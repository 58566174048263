.tagButton {
  /* float: left; */
  margin: 5px 5px 5px 0;
  line-height: 50px;
  background: #fff;
  text-align: center;
  height: 50px;
  border: 1px solid #000000b9;
  padding: 0 20px;
}

.tagGreyButton {
  /* float: left; */
  margin: 5px 5px 5px 0;
  line-height: 50px;
  background: #f2f2f2;
  text-align: center;
  height: 50px;
  border: 1px solid #918b8b;
  padding: 0 20px;
  color: #918b8b;
}

.tagButtonActive {
  /* float: left; */
  margin: 5px 5px 5px 0;
  line-height: 50px;
  background: #fff;
  color: red;
  text-align: center;
  height: 50px;
  padding: 0 20px;
  border: 1px solid #ee1d23;
}

.tag-title {
  margin: 0 5px 10px 0;
  line-height: 20px;
  display: inline;
}

#instock {
  margin-left: 10px;
}

.imgSize {
  width: 400px;
  height: 400px;
  float: right;
}

.product_goodsName {
  font-size: 24px;
  margin-bottom: 10px;
}

.goods_sales {
  display: flex;

}

.goods_sales>div {
  margin-right: 10px;
  margin-bottom: 10px;
  width: 48px;
  height: 40px;
  background: #f2f2f2;
  text-align: center;
  line-height: 40px;
}

.stock_sty {
  margin-left: 10px;
  color: rgba(0, 0, 0, 0.45);
}

.operation_after_login {
  margin: 20px 0;
}