.container1 {
  margin-top: 70px;
}

/* :where(.css-dev-only-do-not-override-12upa3x).ant-table-wrapper .ant-table-thead>tr>th {
  background: #fff
} */

.amount_statistics_sty {
  margin-top: 13px;
  float: right;
}

.amount_statistics_sty>div {
  margin-bottom: 16px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.amount_statistics_sty>div>div {
  margin-left: 24px;
}

.amount_statistics_sty>div>div>span {
  margin-left: 16px;
}

.amount_statistics_sty>div>div>span>span {
  margin-right: 6px;
}

.order_amount {
  color: rgba(0, 0, 0, 0.45);
  font-size: 20px;
}

.offer_amount {
  color: rgba(0, 0, 0, 0.85);
  font-size: 20px;
}

.amount_to_be_paid {
  color: #ee1d23;
  font-size: 20px;
}

.operation_sty {
  display: flex;
  width: 100%;
  justify-content: space-between;
}

.operation_sty>div {
  border-radius: 4px;
}

.operation_sty>div>.btn-solid {
  border-radius: 4px;
}

.keep_shopping_sty {
  margin-right: 20px;
}

.cart_pictures {
  width: 100px;
  height: 100px;
}

.qty-select {}

.inputNumber {
  /* width: 30px; */
}



.specialPrice {
  color: #EE1D23;
  font-size: 24px;
}

.font-size-24 {
  font-size: 24px;
}

.specialPrice>div {
  font-size: 14px;
}

.font_color_red {
  font-weight: 500;
  color: #EE1D23;
  font-size: 20px;
}

.font_color_blue {
  font-weight: 500;
  color: #2E3092;
  font-size: 20px;
}

.font_color_grey {
  font-weight: 500;
  color: rgba(0, 0, 0, 0.45);
  font-size: 20px;
}

.‌friendly-reminder {
  margin-top: 50px;
  margin-left: 30px;
  width: 500px;
  font-size: 18px;
  color: #f75459;
}

.‌friendly-reminder>div {
  text-align: center;
}

.flex-row {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}