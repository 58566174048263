.footerStyle {}

.footerPositions {
  background-color: #F2F2F2;
  text-align: center;
  height: 50px;
  color: rgba(0, 0, 0, 0.45);
  line-height: 50px;
  grid-row: 3
}

footer {
  flex-shrink: 0;
}