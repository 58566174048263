.footer {
  grid-row-start: 2;
  grid-row-end: 3;
}

.outlined_style {
  color: rgba(171, 9, 9, 0.45);

}

.input-number-style::-webkit-inner-spin-button,
.input-number-style::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
  /* 用于 Firefox */
}


.blue_text {
  color: #2E3092
}

.red_text {
  color: #ff4c3b
}

.blue_border_text {
  background-color: #fff;
  border: 1px #2e3092 solid;
  color: #2e3092;
  border-radius: 3px;
  white-space: nowrap;
}

.blue_border_text:hover {
  background-color: #2e3092;
  color: #fff;
  border: 1px #000 solid;
  border-radius: 3px;
  white-space: nowrap;
}

.darkerBlue_text {
  background-color: #2e3092;
  border: 1px #000 solid;
  color: #fff;
  border-radius: 3px;
  white-space: nowrap;
}

.darkerBlue_text:hover {
  background-color: #fff;
  color: #2e3092;
  border: 1px #2e3092 solid;
  border-radius: 3px;
  white-space: nowrap;
}

.darkerRed_text:hover {
  background-color: #fff;
  color: #EE1D23;
  border: 1px #EE1D23 solid;
  border-radius: 3px;
  white-space: nowrap;
}

.darkerRed_text {
  background-color: #EE1D23;
  border: 1px #a70d12 solid;
  color: #fff;
  border-radius: 3px;
  white-space: nowrap;
}

.red_border_text:hover {
  background-color: #EE1D23;
  color: #fff;
  border: 1px #fff solid;
  border-radius: 3px;
  white-space: nowrap;
}

.red_border_text {
  background-color: #fff;
  border: 1px #a70d12 solid;
  color: #EE1D23;
  border-radius: 3px;
  white-space: nowrap;
}

.operation_style {
  display: flex;
  flex-direction: row;
}

.operation_style>button {
  margin: 0 2px;
}

.inputGroup {
  display: flex;
  justify-content: center;
}

.inputGroup>div>button {
  height: 40px;
  background-color: #fff;
  border: 1px solid #f2f2f2;
}

.inputGroup>div>input {
  width: 80px;
  border: #fff;
  background-color: #f2f2f2;
  text-align: center;
  font-size: 20px;
  height: 40px;
}

.padding_10 {
  padding: 0 10px;
}

.text_grey {
  color: rgba(0, 0, 0, 0.45)
}

.text_grey_85 {
  color: rgba(0, 0, 0, 0.85)
}

.col_lamination {
  text-align: right;
  display: flex;
  height: 75px;
  flex-direction: column;
  justify-content: space-between;
}

.myOrder_operation>div {
  margin: 10px;
}

.order_detail_foot {
  display: flex;
  width: 100%;
  justify-content: space-between;
}

.cursor_hand {
  cursor: pointer;
}

.page_loading {
  width: 100%;
  margin-top: 20px;
}

.sale_off {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.sale_off>div {
  margin: 20px 0;
}