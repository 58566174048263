.header_right_logo>a>img {
  width: 64px;
  height: 64px;
}

.header_right_logo {
  display: flex;
  justify-content: center;
  align-items: center;
}

.info_clients_icon {
  color: black;

}

/* .info_clients_icon:hover {
  background-color: #f2f2f2;
  color: black;
} */

.choose_info_clients_icon {
  color: #fff;
  height: 100%;
  background-color: #2e3092;
}

.header_icon_title {
  display: flex;
  flex-direction: row;
  align-items: center;
  color: black;
}

.choose_info_clients_icon:hover {
  background-color: #fff;
  color: black;
}

.UserName {
  margin: 0 10px;
}

.header_icon_title:hover {
  display: flex;
  flex-direction: row;
  align-items: center;
  background-color: #f2f2f2;
  color: black;
}

.select_header_icon_title {
  display: flex;
  flex-direction: row;
  align-items: center;
  background-color: #2e3092;
  border-radius: 4px;
  color: #fff;
}

.select_header_icon_title:hover {
  background-color: #fff;
  border-radius: 4px;
  color: black;
}

.select-UserName {
  margin: 0 10px;
}