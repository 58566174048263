.footerStyle-mobile {
  background-color: #F2F2F2;
  text-align: center;
  color: rgba(0, 0, 0, 0.45);
  grid-row: 3;
  margin-bottom: -50px;
  padding: 10px 0;
  font-size: 10px;
}

.footerPositions {}

footer {
  flex-shrink: 0;
}