.product_img_size {
  width: 235px;
  height: 235px;
}

.product_detail_sty {

  display: flex;
  flex-direction: column;
}

.listShopRow {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
}

.product_list_sty {
  // display: flex;

}

.product_margin {
  margin-bottom: 20px;
  text-align: center;
}

.product-list-item-red {
  position: absolute;
  width: 60px;
  height: 24px;
  line-height: 24px;
  background: #ee1d23;
  color: #fff;
  border-radius: 8px;
  text-align: center;
  top: 8px;
  left: 20px;
}

.row_width {
  width: 100%;
}


.goodsName-style {
  color: #1b1a1a;
  width: 220px;
  height: 46px;
  font-size: 15px;
  // white-space: nowrap;
  // overflow: hidden;
  // text-overflow: ellipsis;
  margin: 10px auto;
  display: -webkit-box;
  /*! autoprefixer: off; */
  -webkit-box-orient: vertical;
  -webkit-box-clamp: 2; //行数;
  overflow: hidden;
  text-align: left;
}

.popover_style {
  margin: 10px;
}

#root .ant-tree-title {
  font-size: 16px;
}