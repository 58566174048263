.product_img_size {
  width: 235px;
  height: 235px;
}

.product_detail_sty {
  margin-left: auto;
  margin-right: auto;
}

.listShopRow {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
}

.product_list_sty {
  // display: flex;

}

.product_margin_mobile {
  margin-bottom: 20px;
  display: flex;
  flex-direction: row;
}

.product-list-item-red {
  position: absolute;
  width: 60px;
  height: 24px;
  line-height: 24px;
  background: #ee1d23;
  color: #fff;
  border-radius: 8px;
  text-align: center;
  top: 8px;
  left: 20px;
}

.row_width {
  width: 100%;
}

.Product-Listing {
  height: 100%;
  overflow: auto
}