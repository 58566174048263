.search_title {
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
}

.product_list_division_sty {
  display: flex;
  flex-direction: column;
}

.product_list_division_sty>div {
  display: flex;
  justify-content: space-between;
}


:where(.css-dev-only-do-not-override-t9vmwr).ant-pagination .ant-pagination-options {
  float: right;
}

.pagination_sty {
  margin-top: 20px;
}

.tabs_info_sty {
  width: 796px;
}



.tree_sty {
  border: 1px solid #f2f2f2;
  padding: 10px 0;
}

.product_list_spin {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 50px 0;
  width: 100%
}

.container-collection {
  height: 100%;
  background-color: #ffffff;
  display: flex;
  justify-content: flex-start;
  align-items: stretch;

  /* [data-prefers-color='dark'] & {
    background-color: unset;
  } */
}

.side {
  flex: none;
}

.main {
  flex: auto;
}

.content-collection {
  height: 100%;
  justify-content: center;
  align-items: center;
  font-size: 32px;
  color: #cccccc;
}