.sidebar_product_recommendation_title {
  display: flex;
  justify-content: space-between;
  width: 260px;
  padding: 13px 10px;
  border-bottom: 1px solid #f2f2f2;
}

.sidebar_layout {
  display: flex;
  justify-content: space-between;
  padding: 0 15px
}

/* .picture_small {
  display: flex;
  justify-content: space-between;
  margin-top: 10px;
}

.picture_small>div {
  width: 120px;
  height: 120px;
  background-color: #F2F2F2;
} */

.outlined_style {
  color: rgba(0, 0, 0, 0.45);

}