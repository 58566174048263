.header_right_logo>a>img {
  width: 64px;
  height: 64px;
}

.header_right_logo {
  display: flex;
  justify-content: center;
  align-items: center;
}

.info_clients_icon {
  color: black;
}

.info_clients_icon:hover {
  background-color: #f2f2f2;
  color: black;
}

.choose_info_clients_icon {
  color: #fff;
  height: 100%;
  background-color: #2e3092;
}

.choose_info_clients_icon:hover {
  background-color: #fff;
  color: black;
}

.header-style {
  position: fixed;
  top: 0;
  width: 100%;
}

.menu {
  height: 50px;
  width: 100%;
  background-color: #eee;
}

.fixed {
  position: fixed;
  width: 100%;
  top: 0;
  z-index: 999;
  transition: all 300ms ease;
  background: #fff;
  box-shadow: 0px 0px 8px #ddd;
  animation: smoothScroll 1s forwards;
}

.NavBar-right {
  margin: 0 5px;
}