.delivery_address_sty {
  height: 110px;
  width: 100%;
  border: 1px solid #F2F2F2;
  padding: 20px;
  display: flex;
  justify-content: space-between;
  font-size: 20px;
  text-align: center;
  align-items: center;
}

.delivery_address_sty {}

.delivery_address_sty>div>div {
  display: flex;
}

.delivery_address_sty>div>div>div {
  margin-right: 20px;
}

.select_delivery_address_sty {
  display: flex;
  margin-left: 20px;
}

.default_address_sty {
  width: 44px;
  height: 24px;
  background: #2e3092;
  border-radius: 8px;
  font-size: 12px;
  color: #fff;
  text-align: center;
  line-height: 24px;

}

.address_sty {
  color: rgba(0, 0, 0, 0.45);
}

.form_deliveryAddress_sty {
  color: rgba(0, 0, 0, 0.45);
  margin-left: 20px;
}

.add_address_btn {
  color: #2e3092;
  font-size: 14px;
  width: 124px;
  height: 50px;
  line-height: 50px;
  border: 1px solid #2e3092;
}

.title_order_confirmation {
  margin: 20px 0 10px 0;
  font-size: 16px;
}

/* .amount_statistics_sty {
  margin-top: 13px;
  float: right;
}

.amount_statistics_sty>div {
  margin-bottom: 16px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
} */

.order_amount {
  color: rgba(0, 0, 0, 0.45);
  font-size: 20px;
}

.offer_amount {
  color: rgba(0, 0, 0, 0.85);
  font-size: 20px;
}

.amount_to_be_paid {
  color: #ee1d23;
  font-size: 20px;
}

.operation_sty {
  display: flex;
  width: 100%;
  justify-content: space-between;
}

.keep_shopping_sty {
  margin-right: 20px;
}

.radio_frame {
  border: 2px solid #2E3092;
  width: 100%;
  padding: 20px;
  margin-bottom: 20px;
}

.radio_frame_none {
  border: 2px solid #F2F2F2;
  width: 100%;
  padding: 10px 20px;
  margin-bottom: 20px;
}

.form_deliveryAddress {
  display: flex;
}

.form_item_deliveryAddress {
  width: 50%;
  padding-right: 20px;
}

.form_item_deliveryAddress_80 {
  /* width: 90%; */
  padding-right: 20px;
}

.radio_frame>h4 {
  margin-bottom: 20px;
}

.radio_frame_none>h4 {
  margin-bottom: 20px;
}

.font-size-20 {
  font-size: 20px;
}

.margin-left-20 {
  margin-left: 20px;
}

.default_address {
  background-color: #2E3092;
  color: #fff;
  font-size: 12px;
  margin: 10px;
}

.form_deliveryAddressList_title {
  width: 690px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.form_deliveryAddress_title {
  display: flex;
  justify-content: space-between;
}

.form_deliveryAddress_title>div {
  padding-right: 20px;
  color: #2E3092;
}

.form_deliveryAddress_title>h4 {
  display: flex;
  padding-right: 20px;
  justify-content: center;
  align-items: center;
}

:where(.css-dev-only-do-not-override-byeoj0).ant-radio-wrapper span.ant-radio+* {
  width: 100%;
}

.color_blue {
  color: #2E3092;
}

.pay_info_sty {
  display: flex;
  justify-content: space-between;
}

.pay_info_sty>div {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.orderCancellation_btn_sty {
  margin-right: 20px;
}

.COD_btn_sty {
  margin-left: 20px;
}

.font-size-16 {
  font-size: 16px;
}

.color-grey {
  color: rgba(0, 0, 0, 0.45);
}

.color-red {
  color: #EE1D23;

}

.color-grey-85 {
  color: rgba(0, 0, 0, 0.85);
}


.font-weight-600 {
  font-weight: 600;
}

.QRCode_sty {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 20px;
}

.simplePay_sty {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 20px;
}

.simplePay_sty>img {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
}

.example_pay {
  margin: 20px 0;
  margin-bottom: 20px;
  padding: 30px 50px;
  text-align: center;
  background: rgba(0, 0, 0, 0.05);
  border-radius: 4px;
}

.closeloading {
  position: absolute;
  right: 30%;
  top: 10%;
  z-index: 1000;
}