.sidebar_product_recommendation_title {
  display: flex;
  justify-content: space-between;
  width: 260px;
  padding: 13px 10px;
  border-bottom: 1px solid #f2f2f2;
}

.sidebar_layout {
  display: flex;
  justify-content: space-between;
  padding: 0 15px
}

.picture_small {
  display: flex;
  /* justify-content: space-between; */
  margin-top: 10px;
  width: 380px;
  overflow: auto;
}

.picture_small>img {
  width: 100px;
  height: 100px;
  background-color: #F2F2F2;
  margin: 0 3px;
}


.selectImg {
  border: 2px solid red;
  border-radius: 10px;
}

.noSelectImg {
  border: 2px solid #fff;
  border-radius: 10px;
}

.related_products_list {
  display: flex;
  flex-direction: column;
  height: 294px;
  animation-timing-function: ease;
}

.related_products_list>div {
  display: flex;
  margin-top: 16px;
}

.related_products_info {
  cursor: pointer;
  position: relative;
  /* animation-name: ball;
  animation-duration: 2s; */
}

.related_products_info>div {
  margin-right: 10px;
}

.related_products_img {
  width: 80px;
  height: 80px;
}

.related_products_img>img {
  width: 80px;
  height: 80px;
}


.related_products_name {
  font-size: 16px;
}

.RelatedProductsStyle {
  overflow: auto;
}

.goodsName_style {
  width: 160px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  -o-text-overflow: ellipsis;
}