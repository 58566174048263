.tagButton {
  /* float: left; */
  margin: 5px 5px 5px 0;
  line-height: 50px;
  background: #fff;
  text-align: center;
  height: 50px;
  border: 1px solid #000000b9;
  padding: 0 20px;
}

.tagGreyButton {
  /* float: left; */
  margin: 5px 5px 5px 0;
  line-height: 50px;
  background: #f2f2f2;
  text-align: center;
  height: 50px;
  border: 1px solid #918b8b;
  padding: 0 20px;
  color: #918b8b;
}

.tagButtonActive {
  /* float: left; */
  margin: 5px 5px 5px 0;
  line-height: 50px;
  background: #fff;
  color: red;
  text-align: center;
  height: 50px;
  padding: 0 20px;
  border: 1px solid #ee1d23;
}

.tag-title {
  margin: 0 5px 10px 0;
  line-height: 20px;
  display: inline;
}

#instock {
  margin-left: 10px;
}

.imgSize {
  width: 400px;
  height: 400px;
  float: right;
}

.product_goodsName {
  font-size: 24px;
  margin-bottom: 10px;
}

.goods_sales {
  display: flex;

}

.goods_sales>div {
  margin-right: 10px;
  margin-bottom: 10px;
  width: 48px;
  height: 40px;
  background: #f2f2f2;
  text-align: center;
  line-height: 40px;
}

.stock_sty {
  margin-left: 10px;
  color: rgba(0, 0, 0, 0.45);
}

.operation_after_login {
  margin: 20px 0;
}

.orderModal {
  /* display: flex;
  flex-direction: row; */
}

.prod_radio_frame {
  border: 2px solid #2E3092;
  width: 100%;
  padding: 20px;
  margin-bottom: 20px;
}

.prod_radio_frame_none {
  border: 2px solid #F2F2F2;
  width: 100%;
  padding: 20px;
  margin-bottom: 20px;
}

.prodmodel_order_info {
  /* display: flex; */
}

.prodmodel_order_info>div {
  display: flex;
  justify-content: space-between;
}

.prodmodel_order_info_prodQty {
  display: flex;
  align-items: center;
}

.prodmodel_order_info_prodQty>span {
  margin-left: 10px;

}

.margin-left-20 {
  margin-left: 20px;
}

.prodmodel_myOrdersList {
  height: 600px;
  overflow-y: auto;
}

.prodmodel_spin {
  width: 100%;
  margin: 10px 0;
  display: flex;
  justify-content: center;
  align-items: center;
}

.prodmodel_button {
  width: 100%;
  display: flex;
  justify-content: flex-end;
}

.prodmodel_button>div {
  width: 100px;
  padding: 10px 20px;
  display: flex;
  justify-content: center;
  align-items: flex-end;
}

.listRecmds_style {
  display: flex;
  flex-direction: row;
  overflow-x: auto;
}

.listRecmds_style>div {
  width: 110px;
  height: 200px;
  margin: 0 10px;
}

.recmds_prod_title {
  margin: 5px 0;
  white-space: nowrap;
  /* 确保文本在一行内显示 */
  overflow: hidden;
  /* 超出容器部分的文本隐藏 */
  text-overflow: ellipsis;
}

.recmds_prod_info {
  display: flex;
  flex-direction: row;
}

.recmds_prod_info>div {
  margin-right: 5px;
}

.addto-existingOrder {
  border: #ee1d23 2px solid;
  padding: 10px;
  border-radius: 3px;
}