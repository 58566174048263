.footer {
  grid-row-start: 2;
  grid-row-end: 3;
}

.TabBar-Style {
  position: fixed;
  width: 100%;
  background-color: #fff;
  bottom: 0px;
  padding: 5px 0;
  height: 55px;
}

.app_style {
  height: calc(100vh - 105px);
}

.divider_style {
  margin: 15px 0 10px 0;
  width: 100%;

  border-bottom: 1px solid #f2f2f2;
}