.search_title {
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
}

.search_title>div {}

.product_list_division_sty {
  display: flex;
  flex-direction: column;
}

.product_list_division_sty>div {
  display: flex;
  justify-content: space-between;
}


:where(.css-dev-only-do-not-override-t9vmwr).ant-pagination .ant-pagination-options {
  float: right;
}

.pagination_sty {
  margin-top: 20px;
}

.tabs_info_sty {
  width: 796px;
}

.tree_son_sty {
  width: 240px;
  margin-right: 20px;
  font-size: 18px;
}

.tree_sty {
  border: 1px solid #f2f2f2;
  padding: 10px 0;
  height: 100%;
}

.product_list_spin {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 50px 0;
  width: 100%
}


.search_input {
  width: 250px;
  margin-right: 20px;
}