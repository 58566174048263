.mainPosition {
  position: relative;
}

.footerPosition {
  position: absolute;
  left: 220px;
  bottom: 189px;
}

.list_home_sty {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0 80px;
  /* transform: translateY(-50%); */
}

/* transform: translateY(0); */

.list_home_products {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}

.list_home_products>div {
  margin: 0 40px 20px 40px;
}

.list_title_sty {
  height: 220px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.list_title_sty>div {
  padding: 16px;
}

.productList_list_item_sty_mb {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0 2px;
}

.productList_all_sty {
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;

}

.productList_all_icon_sty_red {
  margin-left: 8px;
  background-color: #EE1D23;
  color: #fff;
  width: 18px;
  height: 18px;
  border-radius: 22px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.productList_all_icon_sty_blue {
  margin-left: 8px;
  background-color: #2E3092;
  color: #fff;
  width: 18px;
  height: 18px;
  border-radius: 22px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.productList_title_sty {
  color: rgba(0, 0, 0, 0.85);
  font-size: 40px;
}

.productList_all_title_sty {
  color: rgba(0, 0, 0, 0.45);
  font-size: 16px;
}



.productList_offers_red {
  position: absolute;
  width: 60px;
  height: 24px;
  line-height: 24px;
  background: #ee1d23;
  color: #fff;
  border-radius: 8px;
  text-align: center;
  top: 8px;
  left: 8px;
}

.productList_offers_blue {
  position: absolute;
  width: 60px;
  height: 24px;
  line-height: 24px;
  background: #2E3092;
  color: #fff;
  border-radius: 8px;
  text-align: center;
  top: 8px;
  left: 8px;
}

.img_product_picture {
  position: relative;
  width: 235px;
  height: 235px;
  border: 1px solid #F2F2F2;
}

.productList_item_price_sty {
  color: rgba(0, 0, 0, 0.85);
  font-size: 20px;
}

.productList_item_info_sty_mb {
  margin: 8px 0 40px 0;
}

.gutter-row {
  justify-content: left;
  width: 1020px;
}




.homePage-style {
  height: 100vh;
  margin: 0,
}

.homePage-layouts {
  min-height: 100%;

  /* 等于footer的高度 */
  margin-bottom: -50px;
}

.footer,
.push {
  height: 50px;
}

.content {
  height: 200px;
  color: #ffffff;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 48px;
  user-select: none;
}

.swiper-Image {
  position: relative;
}

.swiper-Image-title {
  position: absolute;
  top: 20px;
  left: 40px;
}

.swiper-Image-title>#swiper-title1 {
  font-size: 16px;
  color: rgba(0, 0, 0, 0.45);
}

.swiper-Image-title>#swiper-title2 {
  font-size: 20px;
  color: rgba(0, 0, 0, 0.85);
}

.Card-Image {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.Card-Image>div {
  margin: 5px 0;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
  overflow: hidden;
}

.fontSize-20 {
  font-size: 20px;
}